<template>
  <div>
    <iframe
      :src="iFrameURL"
      style="height:100vh;width:100%;border:0px solid;margin-bottom:-9px;"
    ></iframe>
  </div>
</template>

<script>
import configs from '@/plugins/configs.js'
// import EventBus from '@/plugins/eventBus.js'

const requests = {
  logoutUser: {
    method: 'get',
    url: '/api/common/logout'
  }

}
export default {
  props: {
  },
  data () {
    return {
      accountId: ''
    }
  },
  methods: {
    receiveMessage (event) {
      console.log(event)
      if (event.data === 'pickcelLogout') {
        this.Logout()
      }
    },
    Logout () {
      const params = { account: this.$store.state.auth.user.account }
      const config = { params: params }
      this.$utils
        .callAxios(
          requests.logoutUser.method,
          requests.logoutUser.url,
          config
        )
        .finally(() => {
          this.$store
            .dispatch('auth/logout')
            .catch(error => {
              console.log(error)
            })
            .finally(() => {
              this.$store.dispatch('dataStore/CLEAN')
              this.$router.push('/login')
            })
        })
    }

  },
  computed: {
    iFrameURL () {
      console.log(configs)
      console.log(this.accountID)
      // Create a URL object
      const ServerDetails = new URL(configs.ServerAddress)

      // Extract components
      var protocol = ServerDetails.protocol // includes the colon (":")
      protocol = protocol.replace(':', '')
      const hostname = ServerDetails.hostname
      const port = ServerDetails.port
      const protocolAndHost = configs.ServerAddress.split(':')
        if (protocolAndHost[0] === 'https') {
        protocolAndHost[0] = 'http'
      }
      const finalUrl = protocolAndHost[0] + ':' + protocolAndHost[1]
      // var url = configs.ServerAddress + '/pickcel/#/'
      var url = finalUrl + '/#/'
      var ifurl = ''
      ifurl = url +
      '?client_info=' +
      'NsKQvyOF.zguBCKAfwFCLBgIizGGaClomAJebJVZaRxy' +
      '&account=' +
      this.$store.state.auth.user.account + '-' + this.$store.state.auth.user.company + '&env=' + 'ONPREM' + '&company=' + this.$store.state.auth.user.company + '&scheme=' + protocol + '&host=' + hostname + '&port=' + port
      if (
        this.$store.getters['dataStore/IS_SELECTED_STORE'] &&
      this.$store.getters['dataStore/GET_SELECTED_STORE'].code !== ''
      ) {
        ifurl =
      ifurl +
        '&storeID=' +
        this.$store.getters['dataStore/GET_SELECTED_STORE'].code
      }
      console.log(ifurl)
      return ifurl
    }
  },
  mounted () {
    console.log(this.iFrameURL)
    window.addEventListener('message', this.receiveMessage)
  }

}
</script>

<style></style>
